<template>
    
    <CRow style="margin-top:20px;">
        <CCol sm="12">
            <CTabs style="width:100%;" variant="tabs" :active-tab="activePage">
                <CTab :title="$t('label.resume')">
                    <CCard class="mt-2">
                        <CCardHeader class="text-center">
                            <h5>EJECUCIÓN</h5>
                        </CCardHeader>
                        <CCardBody>
                            <CRow>
                                <CCol sm="6" lg="2">
                                    <CCard class="border border-left-primary shadow h-100">
                                        <CCardBody class="text-center">
                                            <h4>
                                                <CIcon name="crane" />
                                                GRUA 1
                                            </h4>
                                            DESCARGA
                                            <h5 class="my-2">
                                                <b>50</b>
                                            </h5>
                                            MOVIMIENTOS
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol sm="6" lg="2">
                                    <CCard class="border border-left-success shadow h-100">
                                        <CCardBody class="text-center">
                                            <h4>
                                                <CIcon name="crane" />
                                                GRUA 2
                                            </h4>
                                            DESCARGA
                                            <h5 class="my-2">
                                                <b>20</b>
                                            </h5>
                                            MOVIMIENTOS
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol sm="6" lg="2">
                                    <CCard class="border border-left-danger shadow h-100">
                                        <CCardBody class="text-center">
                                            <h4>
                                                <CIcon name="crane" />
                                                GRUA 3
                                            </h4>
                                            DESCARGA
                                            <h5 class="my-2">
                                                <b>10</b>
                                            </h5>
                                            MOVIMIENTOS
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol sm="6" lg="2">
                                    <CCard class="border shadow h-100">
                                        <CCardBody class="text-center">
                                            ESTIMADO
                                            <h1 class="my-2">
                                                <b>9</b>
                                            </h1>
                                            HORAS DE TRABAJO
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol sm="6" lg="2">
                                    <CCard class="border shadow h-100">
                                        <CCardBody class="text-center">
                                            PROMEDIO
                                            <h1 class="my-2">
                                                <b>18</b>
                                            </h1>
                                            MOVIMIENTOS / HORA
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol sm="6" lg="2">
                                    <highcharts :options="chartOptions"></highcharts>
                                </CCol>
                            </CRow>
                            <CRow class="mt-3">
                                <CCol sm="2" lg="6"></CCol>
                                <CCol sm="2" lg="2" class="text-right pt-4">
                                    <CImg src="/img/iconos/Tab-Vista-Transversal.svg" class="halto" />
                                </CCol>
                                <CCol sm="6" lg="2">
                                    <CCard class="border shadow h-100">
                                        <CCardBody class="text-center">
                                            Total
                                            <h1 class="my-2 text-primary">
                                                <b>122</b>
                                            </h1>
                                            MOVIMIENTOS / BUQUE
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol sm="6" lg="2">
                                    
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                    <CCard class="mt-2 pb-4">
                        <CCardHeader class="text-center">
                            <h5>RESUMEN DE OPERACIONES</h5>
                        </CCardHeader>
                        <CCardBody>
                            <h4>DESCARGA</h4>
                            <CRow>
                                <CCol sm="4">
                                    <CCard class="shadow h-100">
                                        <CCardBody class="text-left">
                                            <h4 class="text-center">TIPO</h4>
                                            <CRow class="mb-3">
                                                <CCol sm="12">
                                                    <CRow>
                                                        <CCol sm="9">20' ESTANDARD</CCol>
                                                        <CCol sm="3">100</CCol>
                                                    </CRow>
                                                    <CRow>
                                                        <CCol sm="9">20' REFRIGERADO</CCol>
                                                        <CCol sm="3">10</CCol>
                                                    </CRow>
                                                    <CRow class="font-weight-bold">
                                                        <CCol sm="9">TOTAL 20'</CCol>
                                                        <CCol sm="3">110</CCol>
                                                    </CRow>
                                                </CCol>
                                            </CRow>
                                            <CRow class="mb-3">
                                                <CCol sm="12">
                                                    <CRow>
                                                        <CCol sm="9">40' ESTANDARD</CCol>
                                                        <CCol sm="3">100</CCol>
                                                    </CRow>
                                                    <CRow>
                                                        <CCol sm="9">40' REFRIGERADO</CCol>
                                                        <CCol sm="3">10</CCol>
                                                    </CRow>
                                                    <CRow class="font-weight-bold">
                                                        <CCol sm="9">TOTAL 40'</CCol>
                                                        <CCol sm="3">110</CCol>
                                                    </CRow>
                                                </CCol>
                                            </CRow>
                                            <CRow class="mb-3">
                                                <CCol sm="12">
                                                    <CRow class="font-weight-bold">
                                                        <CCol sm="9">TOTAL</CCol>
                                                        <CCol sm="3">220</CCol>
                                                    </CRow>
                                                </CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol sm="4">
                                    <CCard class="shadow h-100">
                                        <CCardBody class="text-left">
                                            <h4 class="text-center">PUERTO DE ORIGEN</h4>
                                            <CRow>
                                                <CCol sm="9">20' ESTANDARD</CCol>
                                                <CCol sm="3">100</CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol sm="9">20' REFRIGERADO</CCol>
                                                <CCol sm="3">10</CCol>
                                            </CRow>
                                            <CRow class="font-weight-bold">
                                                <CCol sm="9">TOTAL 20'</CCol>
                                                <CCol sm="3">200</CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol sm="4">
                                    <CCard class="shadow h-100">
                                        <CCardBody class="text-left">
                                            <h4 class="text-center">LINEA NAVIERA</h4>
                                            <CRow>
                                                <CCol sm="9">20' ESTANDARD</CCol>
                                                <CCol sm="3">100</CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol sm="9">20' REFRIGERADO</CCol>
                                                <CCol sm="3">10</CCol>
                                            </CRow>
                                            <CRow class="font-weight-bold">
                                                <CCol sm="9">TOTAL 20'</CCol>
                                                <CCol sm="3">200</CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                            </CRow>
                            <hr />
                            <h4>CARGA</h4>
                            <CRow>
                                <CCol sm="4">
                                    <CCard class="shadow h-100">
                                        <CCardBody class="text-left">
                                            <h4 class="text-center">TIPO</h4>
                                            <CRow class="mb-3">
                                                <CCol sm="12">
                                                    <CRow>
                                                        <CCol sm="9">20' ESTANDARD</CCol>
                                                        <CCol sm="3">100</CCol>
                                                    </CRow>
                                                    <CRow>
                                                        <CCol sm="9">20' REFRIGERADO</CCol>
                                                        <CCol sm="3">10</CCol>
                                                    </CRow>
                                                    <CRow class="font-weight-bold">
                                                        <CCol sm="9">TOTAL 20'</CCol>
                                                        <CCol sm="3">110</CCol>
                                                    </CRow>
                                                </CCol>
                                            </CRow>
                                            <CRow class="mb-3">
                                                <CCol sm="12">
                                                    <CRow>
                                                        <CCol sm="9">40' ESTANDARD</CCol>
                                                        <CCol sm="3">100</CCol>
                                                    </CRow>
                                                    <CRow>
                                                        <CCol sm="9">40' REFRIGERADO</CCol>
                                                        <CCol sm="3">10</CCol>
                                                    </CRow>
                                                    <CRow class="font-weight-bold">
                                                        <CCol sm="9">TOTAL 40'</CCol>
                                                        <CCol sm="3">110</CCol>
                                                    </CRow>
                                                </CCol>
                                            </CRow>
                                            <CRow class="mb-3">
                                                <CCol sm="12">
                                                    <CRow class="font-weight-bold">
                                                        <CCol sm="9">TOTAL</CCol>
                                                        <CCol sm="3">220</CCol>
                                                    </CRow>
                                                </CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol sm="4">
                                    <CCard class="shadow h-100">
                                        <CCardBody class="text-left">
                                            <h4 class="text-center">PUERTO DE ORIGEN</h4>
                                            <CRow>
                                                <CCol sm="9">20' ESTANDARD</CCol>
                                                <CCol sm="3">100</CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol sm="9">20' REFRIGERADO</CCol>
                                                <CCol sm="3">10</CCol>
                                            </CRow>
                                            <CRow class="font-weight-bold">
                                                <CCol sm="9">TOTAL 20'</CCol>
                                                <CCol sm="3">200</CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol sm="4">
                                    <CCard class="shadow h-100">
                                        <CCardBody class="text-left">
                                            <h4 class="text-center">LINEA NAVIERA</h4>
                                            <CRow>
                                                <CCol sm="9">20' ESTANDARD</CCol>
                                                <CCol sm="3">100</CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol sm="9">20' REFRIGERADO</CCol>
                                                <CCol sm="3">10</CCol>
                                            </CRow>
                                            <CRow class="font-weight-bold">
                                                <CCol sm="9">TOTAL 20'</CCol>
                                                <CCol sm="3">200</CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CTab>
                <CTab :title="$t('label.projection')">
                    <CCard class="mt-2">
                        <CCardHeader class="text-center">
                            <h5>PLANIFICACIÓN</h5>
                        </CCardHeader>
                        <CCardBody>
                            <CRow>
                                <CCol sm="4" lg="2">
                                    <CCard class="border shadow h-100">
                                        <CCardBody class="text-center">
                                            <CRow>
                                                <CCol sm="12">
                                                    <h4>
                                                        <CIcon name="crane" />
                                                        GRUA 1
                                                    </h4>
                                                    MOVIMIENTOS
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol sm="12" class="mt-2">
                                                    <b>DESCARGA</b><br />
                                                    <h4 class="my-2 text-warning">
                                                        50
                                                    </h4>
                                                </CCol>
                                                <CCol sm="12" class="mt-2">
                                                    <b>CARGA</b><br />
                                                    <h4 class="my-2 text-warning">
                                                        50
                                                    </h4>
                                                </CCol>
                                                <CCol sm="12" class="mt-2">
                                                    <hr />
                                                    <b>TOTAL</b><br />
                                                    <h4 class="my-2 text-warning">
                                                        50
                                                    </h4>
                                                </CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol sm="4" lg="2">
                                    <CCard class="border shadow h-100">
                                        <CCardBody class="text-center">
                                            <CRow>
                                                <CCol sm="12">
                                                    <h4>
                                                        <CIcon name="crane" />
                                                        GRUA 2
                                                    </h4>
                                                    MOVIMIENTOS
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol sm="12" class="mt-2">
                                                    <b>DESCARGA</b><br />
                                                    <h4 class="my-2 text-warning">
                                                        50
                                                    </h4>
                                                </CCol>
                                                <CCol sm="12" class="mt-2">
                                                    <b>CARGA</b><br />
                                                    <h4 class="my-2 text-warning">
                                                        50
                                                    </h4>
                                                </CCol>
                                                <CCol sm="12" class="mt-2">
                                                    <hr />
                                                    <b>TOTAL</b><br />
                                                    <h4 class="my-2 text-warning">
                                                        50
                                                    </h4>
                                                </CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol sm="4" lg="2">
                                    <CCard class="border shadow h-100">
                                        <CCardBody class="text-center">
                                            <CRow>
                                                <CCol sm="12">
                                                    <h4>
                                                        <CIcon name="crane" />
                                                        GRUA 3
                                                    </h4>
                                                    MOVIMIENTOS
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol sm="12" class="mt-2">
                                                    <b>DESCARGA</b><br />
                                                    <h4 class="my-2 text-warning">
                                                        50
                                                    </h4>
                                                </CCol>
                                                <CCol sm="12" class="mt-2">
                                                    <b>CARGA</b><br />
                                                    <h4 class="my-2 text-warning">
                                                        50
                                                    </h4>
                                                </CCol>
                                                <CCol sm="12" class="mt-2">
                                                    <hr />
                                                    <b>TOTAL</b><br />
                                                    <h4 class="my-2 text-warning">
                                                        50
                                                    </h4>
                                                </CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol sm="12" lg="6">
                                    <CRow>
                                        <CCol sm="4">
                                            <CCard class="border shadow h-100">
                                                <CCardBody class="text-center">
                                                    ESTIMADO
                                                    <h1 class="my-2 text-success">
                                                        <b>9</b>
                                                    </h1>
                                                    HORAS DE TRABAJO
                                                </CCardBody>
                                            </CCard>
                                        </CCol>
                                        <CCol sm="4">
                                            <CCard class="border shadow h-100">
                                                <CCardBody class="text-center">
                                                    PROMEDIO
                                                    <h1 class="my-2 text-success">
                                                        <b>9</b>
                                                    </h1>
                                                    MOVIMIENTOS / HORA (FIJO)
                                                </CCardBody>
                                            </CCard>
                                        </CCol>
                                        <CCol sm="4">
                                            <CCard class="border shadow h-100">
                                                <CCardBody class="text-center">
                                                    PROMEDIO
                                                    <h1 class="my-2 text-success">
                                                        <b>9</b>
                                                    </h1>
                                                    MOVIMIENTOS / HORA (ESTIMADO)
                                                </CCardBody>
                                            </CCard>
                                        </CCol>
                                    </CRow>
                                    <CRow class="mt-3">
                                        <CCol sm="5" class="text-right pt-4">
                                            <CImg src="/img/iconos/Tab-Vista-Transversal.svg" class="halto" />
                                        </CCol>
                                        <CCol sm="6">
                                            <CCard class="border shadow h-100">
                                                <CCardBody class="text-center">
                                                    Total
                                                    <h1 class="my-2 text-warning">
                                                        <b>122</b>
                                                    </h1>
                                                    MOVIMIENTOS / BUQUE
                                                </CCardBody>
                                            </CCard>
                                        </CCol>
                                    </CRow>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                    <CCard class="mt-2 pb-4">
                        <CCardHeader class="text-center">
                            <h5>DETALLE</h5>
                        </CCardHeader>
                        <CCardBody>
                            <h4>DESCARGA</h4>
                            <CRow>
                                <CCol sm="4">
                                    <CCard class="shadow h-100">
                                        <CCardBody class="text-left">
                                            <h4 class="text-center">TIPO</h4>
                                            <CRow class="mb-3">
                                                <CCol sm="12">
                                                    <CRow>
                                                        <CCol sm="9">20' ESTANDARD</CCol>
                                                        <CCol sm="3">100</CCol>
                                                    </CRow>
                                                    <CRow>
                                                        <CCol sm="9">20' REFRIGERADO</CCol>
                                                        <CCol sm="3">10</CCol>
                                                    </CRow>
                                                    <CRow class="font-weight-bold">
                                                        <CCol sm="9">TOTAL 20'</CCol>
                                                        <CCol sm="3">110</CCol>
                                                    </CRow>
                                                </CCol>
                                            </CRow>
                                            <CRow class="mb-3">
                                                <CCol sm="12">
                                                    <CRow>
                                                        <CCol sm="9">40' ESTANDARD</CCol>
                                                        <CCol sm="3">100</CCol>
                                                    </CRow>
                                                    <CRow>
                                                        <CCol sm="9">40' REFRIGERADO</CCol>
                                                        <CCol sm="3">10</CCol>
                                                    </CRow>
                                                    <CRow class="font-weight-bold">
                                                        <CCol sm="9">TOTAL 40'</CCol>
                                                        <CCol sm="3">110</CCol>
                                                    </CRow>
                                                </CCol>
                                            </CRow>
                                            <CRow class="mb-3">
                                                <CCol sm="12">
                                                    <CRow class="font-weight-bold">
                                                        <CCol sm="9">TOTAL</CCol>
                                                        <CCol sm="3">220</CCol>
                                                    </CRow>
                                                </CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol sm="4">
                                    <CCard class="shadow h-100">
                                        <CCardBody class="text-left">
                                            <h4 class="text-center">PUERTO DE ORIGEN</h4>
                                            <CRow>
                                                <CCol sm="9">20' ESTANDARD</CCol>
                                                <CCol sm="3">100</CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol sm="9">20' REFRIGERADO</CCol>
                                                <CCol sm="3">10</CCol>
                                            </CRow>
                                            <CRow class="font-weight-bold">
                                                <CCol sm="9">TOTAL 20'</CCol>
                                                <CCol sm="3">200</CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol sm="4">
                                    <CCard class="shadow h-100">
                                        <CCardBody class="text-left">
                                            <h4 class="text-center">LINEA NAVIERA</h4>
                                            <CRow>
                                                <CCol sm="9">20' ESTANDARD</CCol>
                                                <CCol sm="3">100</CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol sm="9">20' REFRIGERADO</CCol>
                                                <CCol sm="3">10</CCol>
                                            </CRow>
                                            <CRow class="font-weight-bold">
                                                <CCol sm="9">TOTAL 20'</CCol>
                                                <CCol sm="3">200</CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                            </CRow>
                            <hr />
                            <h4>CARGA</h4>
                            <CRow>
                                <CCol sm="4">
                                    <CCard class="shadow h-100">
                                        <CCardBody class="text-left">
                                            <h4 class="text-center">TIPO</h4>
                                            <CRow class="mb-3">
                                                <CCol sm="12">
                                                    <CRow>
                                                        <CCol sm="9">20' ESTANDARD</CCol>
                                                        <CCol sm="3">100</CCol>
                                                    </CRow>
                                                    <CRow>
                                                        <CCol sm="9">20' REFRIGERADO</CCol>
                                                        <CCol sm="3">10</CCol>
                                                    </CRow>
                                                    <CRow class="font-weight-bold">
                                                        <CCol sm="9">TOTAL 20'</CCol>
                                                        <CCol sm="3">110</CCol>
                                                    </CRow>
                                                </CCol>
                                            </CRow>
                                            <CRow class="mb-3">
                                                <CCol sm="12">
                                                    <CRow>
                                                        <CCol sm="9">40' ESTANDARD</CCol>
                                                        <CCol sm="3">100</CCol>
                                                    </CRow>
                                                    <CRow>
                                                        <CCol sm="9">40' REFRIGERADO</CCol>
                                                        <CCol sm="3">10</CCol>
                                                    </CRow>
                                                    <CRow class="font-weight-bold">
                                                        <CCol sm="9">TOTAL 40'</CCol>
                                                        <CCol sm="3">110</CCol>
                                                    </CRow>
                                                </CCol>
                                            </CRow>
                                            <CRow class="mb-3">
                                                <CCol sm="12">
                                                    <CRow class="font-weight-bold">
                                                        <CCol sm="9">TOTAL</CCol>
                                                        <CCol sm="3">220</CCol>
                                                    </CRow>
                                                </CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol sm="4">
                                    <CCard class="shadow h-100">
                                        <CCardBody class="text-left">
                                            <h4 class="text-center">PUERTO DE ORIGEN</h4>
                                            <CRow>
                                                <CCol sm="9">20' ESTANDARD</CCol>
                                                <CCol sm="3">100</CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol sm="9">20' REFRIGERADO</CCol>
                                                <CCol sm="3">10</CCol>
                                            </CRow>
                                            <CRow class="font-weight-bold">
                                                <CCol sm="9">TOTAL 20'</CCol>
                                                <CCol sm="3">200</CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol sm="4">
                                    <CCard class="shadow h-100">
                                        <CCardBody class="text-left">
                                            <h4 class="text-center">LINEA NAVIERA</h4>
                                            <CRow>
                                                <CCol sm="9">20' ESTANDARD</CCol>
                                                <CCol sm="3">100</CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol sm="9">20' REFRIGERADO</CCol>
                                                <CCol sm="3">10</CCol>
                                            </CRow>
                                            <CRow class="font-weight-bold">
                                                <CCol sm="9">TOTAL 20'</CCol>
                                                <CCol sm="3">200</CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CTab>
            </CTabs>
        </CCol>
    </CRow>
</template>

<script>
    import { mapState } from 'vuex';
    import { tableTextTranslatedHelpers, alertPropertiesHelpers } from '@/_helpers/funciones';
    import Highcharts from 'highcharts'
    import {Chart} from 'highcharts-vue'

    //data
    function data() {
        return {
            isLoading:false,
            items:[],
            activePage: 0,
            chartOptions: {
                chart: {
                    height: 150,
                },
                title: {
                    text: ''
                },
                legend: { 
                    enabled:false 
                },
                yAxis: {
                    title: {
                        text: ''
                    }
                },
                series: [{
                data: [1,2,3] // sample data
                }]
            }
        }
    }

    //methods
    
    export default {
        name: 'app-proyeccion',
        data,
        props: ['loading'],
        methods: {
            
        },
        computed: {
            ...mapState({
                filtroGrua: state => state.planificacionestiba.filtroGrua,
                planificacionId: state => state.planificacionestiba.planificacionId,
                ItineraryId: state => state.planificacionestiba.ItineraryId,
            })
        },
        components: {
            highcharts: Chart 
        },
        watch:{

        }
    } 
</script>

<style>
    .center-cell {
        text-align: center;
        vertical-align: middle;
    }

    .border-left-primary {
        border-left: .25rem solid #4e73df !important;
    }

    .border-left-success {
        border-left: .25rem solid #1cc88a !important;
    }

    .border-left-warning {
        border-left: .25rem solid #f6c23e !important;
    }

    .border-left-danger {
        border-left: .25rem solid #e74a3b !important;
    }

    .halto {
        width: 120px;
    }
</style>